<template>
  <div class="page">
    <el-row>
      <el-form
        ref="form"
        label-width="120px"
        :model="form"
        :rules="rules"
      >
        <el-col :span="12">
          <el-form-item label="优惠券名" prop="name">
            <el-input
              v-model="form.name"
              size="small"
              style="width: 400px"
              placeholder="请输入优惠券名称"
            />
          </el-form-item>
          <el-form-item label="优惠券描述">
            <el-input
                v-model="form.simple_descr"
                size="small"
                style="width: 400px"
            />
          </el-form-item>
          <el-form-item label="优惠券类型" prop="coupon_type">
            <el-radio-group
              size="small"
              v-model="form.preferential_type"
              @change="getCouponType"
            >
              <el-radio-button :label="0">满减</el-radio-button>
              <el-radio-button :label="1">折扣</el-radio-button>
              <el-radio-button :label="2">运费</el-radio-button>
              <el-radio-button :label="3">赠品</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="form.preferential_type === 3" label="选择赠品">
            <el-table :data="form.discounts.gift" height="300px">
              <el-table-column align="center" label="商品图片" prop="imgUrl">
                <template #default="{ row }">
                  <el-image
                    v-if="row.imgUrl"
                    :src="row.imgUrl"
                    class="commodity_pic"
                  />
                  <span v-else>暂无图片</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="商品名称" prop="name" />
              <el-table-column align="center" label="数量">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.num" size="small" :min="1" />
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleGiftDelete(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <PopView
              :visible.sync="showPopGift"
              title="选择商品"
              @confirm="chooseGoodsSubmit"
            />
            <el-button @click="showPopGift = true" size="small" style="margin-top: 10px">选择赠品</el-button>
          </el-form-item>
          <el-form-item v-show="form.preferential_type === 0" label="满减规则">
            <span
              >当订单金额(非实付，不包括运费)满<el-input
                type="number"
                v-model="form.discounts.standard_money"
                size="small"
                style="width: 100px;margin: 0 5px"
              />元，减<el-input
                type="number"
                v-model="form.discounts.reduce_money"
                size="small"
                style="width: 100px;margin: 0 5px"
              />元</span
            >
          </el-form-item>
          <el-form-item v-show="form.preferential_type == 1" label="折扣规则">
            <span
              >除去运费后，按订单金额<el-input
                type="number"
                v-model="form.discounts.discount"
                size="small"
                style="width: 100px;margin: 0 5px"
              />%支付</span
            >
          </el-form-item>
          <el-form-item v-show="form.preferential_type == 2" label="运费规则">
            <el-radio v-model="form.discounts.mail_type" :label="1"
              >包邮</el-radio
            >
            <el-radio v-model="form.discounts.mail_type" :label="2"
              >减免运费
              <el-input
                type="number"
                v-model="form.discounts.breaks_money"
                size="small"
                style="width: 100px;margin: 0 5px"
              />
              元
            </el-radio>
          </el-form-item>
          <el-form-item label="发放数量">
            <!--          <el-radio v-model="form.quantity_astrict" @click="form.quantity = 0" :label="0">无限制</el-radio>
                      <el-radio v-model="form.quantity_astrict" :label="1">限
                        <el-input v-model="form.quantity" size="small" style="width: 100px"/>
                        张
                      </el-radio>-->
            <el-radio-group
              size="small"
              v-model="form.quantity_astrict"
              @change="limitType"
            >
              <el-radio :label="0" @click="form.quantity = 0">无限制</el-radio>
              <el-radio :label="1"
                >限
                <el-input
                  v-model="form.quantity"
                  :disabled="form.quantity_astrict == 1 ? false : true"
                  size="small"
                  style="width: 100px;margin: 0 5px"
                />
                张
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发放时间" prop="grant_begin_time">
            <el-date-picker
              v-model="deposit_time"
              size="small"
              type="datetimerange"
              @change="getDepositTime"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right"
            />
          </el-form-item>
          <el-form-item label="发放方式">
            <el-tooltip placement="bottom-start">
              <div slot="content">
                会员自行领取:会被展示在网页上;<br />后台工作人员派送:只能工作人员在后台操作生成兑换码或者连接;<br />会员凭码兑换优惠券或打开链接获取优惠券;
              </div>
              <i class="el-icon-info" style="margin: 0 10px 0 -10px"></i>
            </el-tooltip>
            <el-radio-group v-model="form.grant_way">
              <el-radio :label="0">会员自行领取(通过App/网页等方式)</el-radio>
              <el-radio :label="1">后台工作人员操作</el-radio>
              <el-radio :label="2">生成领取代码</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优惠码">
            <el-input v-model="form.code" size="small" style="width: 100px;margin: 0 5px" />
            <el-button size="small" style="margin-left: 10px" type="primary"
              >复制优惠码</el-button
            >
            <el-button size="small" type="primary">生成激活链接</el-button>
          </el-form-item>
          <el-form-item label="领取限制">
            <el-radio-group
              v-model="form.get_astrict.type"
              style="margin-top: 15px"
              @change="getLimitType"
            >
              <el-col :span="24">
                <el-radio :label="0"
                  >限领
                  <el-input
                    type="number"
                    v-model="limitObj.num1"
                    size="small"
                    style="width: 100px;margin: 0 5px"
                  />
                  张
                </el-radio>
              </el-col>
              <el-col :span="24">
                <el-radio :label="1" style="margin-top: 10px"
                  >每间隔
                  <el-input
                    type="number"
                    v-model="form.get_astrict.time"
                    size="small"
                    style="width: 100px;margin: 0 5px"
                  />
                  小时领取
                  <el-input
                    type="number"
                    v-model="limitObj.num2"
                    size="small"
                    style="width: 100px;margin: 0 5px"
                  />
                  张
                </el-radio>
              </el-col>
              <el-col :span="24">
                <el-radio :label="2" style="margin-top: 10px"
                  >每次使用完后可领取
                  <el-input
                    type="number"
                    v-model="limitObj.num3"
                    size="small"
                    style="width: 100px;margin: 0 5px"
                  />
                  张
                </el-radio>
              </el-col>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="使用时间">
            <el-radio
              v-model="form.use_astrict"
              :label="0"
              @change="form.day = 0"
              >统一有效期</el-radio
            >
            <el-radio
              v-model="form.use_astrict"
              :label="1"
              @change="effective_time = [];form.use_begin_time='';form.use_end_time=''"
              >领取后天数</el-radio
            >
          </el-form-item>
          <el-form-item label="有效期" v-show="form.use_astrict === 0">
            <el-date-picker
              v-model="effective_time"
              size="small"
              type="datetimerange"
              @change="getEffectiveTime"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right"
            />
          </el-form-item>
          <el-form-item label="失效时间" v-show="form.use_astrict == 1">
            领取优惠券后，有效期为
            <el-input
              v-model="form.day"
              type="number"
              size="small"
              style="width: 100px;margin: 0 5px"
            />
            天
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="适用范围">
            <el-radio-group
              size="small"
              v-model="form.range_of_application"
              @change="getRangeType"
            >
              <el-radio-button :label="0">通用</el-radio-button>
              <el-radio-button :label="1">限定分类</el-radio-button>
              <el-radio-button :label="2">限定商品</el-radio-button>
              <el-radio-button :label="3">限定店铺</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="限定分类"
            v-show="form.range_of_application == 1"
          >
            <el-cascader
              v-model="form.range_json.qualified_type"
              :options="valueArr"
              :props="{ multiple: true, checkStrictly: true,value:'id',label:'name',children:'subclass',emitPath:false }"
              size="small"
              placeholder="请选择参数"
              style="width: 400px"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item
            label="限定商品"
            v-show="form.range_of_application == 2"
          >
            <div style="margin-top: 10px;padding-right: 20px">
              <el-table
                :data="s_list"
                size="small"
                @selection-change="setSelectRows"
                height="300px"
              >
                <el-table-column
                  align="center"
                  show-overflow-tooltip
                  type="selection"
                />
                <el-table-column align="center" label="商品图片" prop="imgUrl">
                  <template #default="{ row }">
                    <el-image
                      v-if="row.imgUrl"
                      :src="row.imgUrl"
                      class="commodity_pic"
                    />
                    <span v-else>暂无图片</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="商品名称" prop="name" />
                <el-table-column align="center" label="商品价格" prop="price" />
                <el-table-column align="center" label="库存" prop="stock" />
                <el-table-column align="center" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" @click="handleDelete(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="showPop = true"
                size="small"
                style="margin-top: 10px"
              >
                选择商品
              </el-button>
              <el-button
                v-if="s_list.length > 0"
                icon="el-icon-delete"
                type="danger"
                size="small"
                style="margin-top: 10px"
                @click="handleDeleteSelected"
                >批量删除
              </el-button>
              <el-button
                v-else
                icon="el-icon-delete"
                type="danger"
                size="small"
                style="margin-top: 10px"
                disabled
                >批量删除
              </el-button>
              <PopView
                :visible.sync="showPop"
                title="选择商品"
                @confirm="showPopSubmit"
              ></PopView>
            </div>
          </el-form-item>
          <el-form-item
            label="限定店铺"
            v-show="form.range_of_application == 3"
          >
            <div style="margin-top: 10px; width: 70%; height: 50px">
              <el-button type="primary" style="margin-left: 20px" size="small">
                to be...
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="是否发布">
            <el-tooltip
              :content="form.is_issue == 1 ? '是' : '否'"
              :enterable="false"
              placement="top"
            >
              <el-switch
                v-model="form.is_issue"
                active-color="#13ce66"
                :active-value="1"
                inactive-color="#ff4949"
                :inactive-value="0"
              />
            </el-tooltip>
          </el-form-item>
            <div style="color: red;margin-top: 60px;padding: 20px">使用时间固定为: 统一有效期适用范围要选择通用优惠券类型只支持 满减，其他方式不要选<br/><br/>2023.9.4</div>
<!--          <el-form-item label="是否已审核">-->
<!--            <el-tooltip-->
<!--              :content="form.is_audit == 1 ? '是' : '否'"-->
<!--              :enterable="false"-->
<!--              placement="top"-->
<!--            >-->
<!--              <el-switch-->
<!--                v-model="form.is_audit"-->
<!--                active-color="#13ce66"-->
<!--                :active-value="1"-->
<!--                inactive-color="#ff4949"-->
<!--                :inactive-value="0"-->
<!--              />-->
<!--            </el-tooltip>-->
<!--          </el-form-item>-->
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="submitForm"> 保存 </el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import PopView from "@/views/components/popView";
import {
  apiGetCategoryList,
  apiGetCouponInfo,
  apiUpdateCoupon,
} from "@/request/api";
import { formatDate } from "@/utils/theTimeStamp";

export default {
  name: "editorcoupons",
  components: { PopView },
  props: {
    chooseGoods: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPop: false,
      showPopGift: false,
      list: [],
      s_list: [],
      deposit_time: [],
      effective_time: [],
      form: {
        id: "",
        name: "",
        simple_descr:'',
        preferential_type: 0, //优惠券类型
        discounts: {
          standard_money: 0, //达标金额
          reduce_money: 0, //满减金额
          discount: 0, //折扣比例
          mail_type: 0, //0包邮 1见面运费
          breaks_money: 0, //减免运费金额
          gift: [],
        },
        quantity_astrict: 0, //领取限制
        quantity: 0, //限制优惠券领取数量
        grant_begin_time: "", //发放开始时间
        grant_end_time: "", //发放结束时间
        grant_way: 0, //发放方式
        code: 0, //优惠码
        get_astrict: {
          //领取限制
          type: 0,
          time: 24,
          number: 1,
        },
        use_astrict: 0, //使用时间0有效期内1领取后n天
        use_begin_time: "",
        use_end_time: "",
        day: 0,
        range_of_application: 0, //适用范围
        range_json: {
          qualified_type: [],
          goods: [],
        },
        is_issue: 1,
        is_audit: 0,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "优惠券名称不能为空" },
        ],
        grant_begin_time: [
          { required: true, trigger: "blur", message: "发放时间不能为空" },
        ],
      },
      limitObj: {
        num1: 1,
        num2: 1,
        num3: 1,
      },
      selectRows: "",
      queryForm: {
        curr_page: 1,
        page_size: 10,
      },
      valueArr: [],
    };
  },
  created() {
    this.getCategoryList();
    if (this.$route.query.id) {
      this.getCouponDetails();
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val;
    },
    //获取优惠券详情
    getCouponDetails() {
      apiGetCouponInfo({ id: this.$route.query.id }).then((res) => {
        if (res.code == 200) {
          const tempObj = res.data;
          this.form = {
            id: tempObj.id,
            name: tempObj.name,
            simple_descr: tempObj.simple_descr,
            preferential_type: tempObj.preferential_type, //优惠券类型
            discounts: JSON.parse(tempObj.discounts),
            quantity_astrict: tempObj.quantity_astrict, //领取限制
            quantity: tempObj.quantity, //限制优惠券领取数量
            grant_begin_time: this.formatDate(tempObj.grant_begin_time), //发放开始时间
            grant_end_time: this.formatDate(tempObj.grant_end_time), //发放结束时间
            grant_way: tempObj.grant_way, //发放方式
            code: tempObj.code, //优惠码
            get_astrict: JSON.parse(tempObj.get_astrict),
            use_astrict: tempObj.use_astrict, //使用时间0有效期内1领取后n天
            use_begin_time: this.formatDate(tempObj.use_begin_time),
            use_end_time: this.formatDate(tempObj.use_end_time),
            day: tempObj.day,
            range_of_application: tempObj.range_of_application, //适用范围
            range_json: JSON.parse(tempObj.range_json),
            is_issue: tempObj.is_issue,
            is_audit: tempObj.is_audit,
          };
          this.deposit_time = [
            this.formatDate(tempObj.grant_begin_time),
            this.formatDate(tempObj.grant_end_time),
          ];
          this.effective_time = [
            this.formatDate(tempObj.use_begin_time),
            this.formatDate(tempObj.use_end_time),
          ];
          if (tempObj.range_of_application == 2) {
            this.s_list = tempObj.sku_info;
          }
        }
      });
    },
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      // console.log(new Date(time))
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    //监听优惠券类型
    getCouponType(event) {
      if (event == 1) {
        this.form.discounts.standard_money = 0;
        this.form.discounts.reduce_money = 0;
        this.form.discounts.mail_type = 0;
        this.form.discounts.breaks_money = 0;
      } else if (event == 2) {
        this.form.discounts.standard_money = 0;
        this.form.discounts.reduce_money = 0;
        this.form.discounts.discount = 0;
      } else if (event == 3) {
        this.form.discounts.standard_money = 0;
        this.form.discounts.reduce_money = 0;
        this.form.discounts.discount = 0;
        this.form.discounts.mail_type = 0;
        this.form.discounts.breaks_money = 0;
      } else {
        this.form.discounts.discount = 0;
        this.form.discounts.mail_type = 0;
        this.form.discounts.breaks_money = 0;
      }
    },
    limitType(e) {
      if (e != 1) {
        this.form.quantity = 0;
      }
    },
    getLimitType(e) {
      if (e == 1) {
        this.limitObj.num1 = 1;
        this.limitObj.num3 = 1;
      } else if (e == 2) {
        this.limitObj.num1 = 1;
        this.form.get_astrict.time = 24;
        this.limitObj.num2 = 1;
      } else {
        this.form.get_astrict.time = 24;
        this.limitObj.num2 = 1;
        this.limitObj.num3 = 1;
      }
    },
    getRangeType(e) {
      if (e != 1) {
        this.form.range_json.qualified_type = [];
      } else if (e != 2) {
        this.s_list = [];
      }
    },
    //获取商品分类
    getCategoryList() {
      apiGetCategoryList().then((res) => {
        if (res.code == 200) {
          this.valueArr = res.data;
        }
      });
    },
    //获取时间段
    getDepositTime(event) {
      if (event != null) {
        this.form.grant_begin_time = event[0];
        this.form.grant_end_time = event[1];
      } else {
        this.form.grant_begin_time = "";
        this.form.grant_end_time = "";
      }
    },
    //
    getEffectiveTime(event) {
      if (event != null) {
        this.form.use_begin_time = event[0];
        this.form.use_end_time = event[1];
      } else {
        this.form.use_begin_time = "";
        this.form.use_end_time = "";
      }
    },
    //选择商品
    showPopSubmit(e) {
      if (e) {
        e.forEach((item) => {
          this.s_list.push({
            skuid: item.skuid,
            imgUrl: item.gallery, //商品图片
            name: item.name, //商品名
            price: item.price, //价格
            stock: item.stock, //库存
          });
        });
        const smap = new Map();
        // 对id属性进行去重
        this.s_list = this.s_list.filter(
          (key) => !smap.has(key.skuid) && smap.set(key.skuid, 1)
        );
      }
    },
    chooseGoodsSubmit(e) {
      if (e) {
        e.forEach((item) => {
          this.form.discounts.gift.push({
            sku: item.skuid,
            imgUrl: item.gallery, //商品图片
            name: item.name, //商品名
            num: 1,
          });
        });
        const smap = new Map();
        // 对id属性进行去重
        this.form.discounts.gift = this.form.discounts.gift.filter(
          (key) => !smap.has(key.sku) && smap.set(key.sku, 1)
        );
      }
    },
    //删除选中商品
    handleDelete(index) {
        this.$confirm("你确定要删除当前项吗", null, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.s_list.splice(index,1)
        });
    },
    handleDeleteSelected(){
      this.$confirm("你确定要删除所有选中项吗", null, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        for (var j = 0; j < this.selectRows.length; j++) {
          this.s_list.map((val, i) => {
            if (val.skuid == this.selectRows[j].skuid) {
              this.s_list.splice(i, 1);
            }
          });
        }
      });
    },
    handleGiftDelete(index) {
      this.$confirm("你确定要删除当前项吗", null, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.discounts.gift.splice(index,1)
      });
    },
    submitForm() {
      this.form.range_json.goods = [];
      if (this.form.get_astrict.type == 1) {
        this.form.get_astrict.number = this.limitObj.num2;
      } else if (this.form.get_astrict.type == 2) {
        this.form.get_astrict.number = this.limitObj.num3;
      } else {
        this.form.get_astrict.number = this.limitObj.num1;
      }
      if (this.form.range_of_application == 2) {
        if (this.s_list.length > 0) {
          this.s_list.forEach((item) => {
            this.form.range_json.goods.push(item.skuid);
          });
        } else {
          this.$message.error("限定商品不能为空");
          return;
        }
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          apiUpdateCoupon(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #ffffff;
  padding: 20px;
}
</style>
